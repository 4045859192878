import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import COMPONENTS from '../../../../../bi/constants/components';

import { Button } from '../../../../../components/button';

import styles from '../../styles/dialogDownloadXmlTickets.module.css';

const LABELS = {
  TITLE: 'Ссылки для скачивания XML',
  BUTTON: 'Готово',
  TEXT: 'Ссылка',
  DOWNLOAD: {
    YES: 'скачана',
    NO: 'не скачана',
  },
};

const {
  DIALOG: { WIDTH: { SMALL } },
} = COMPONENTS;

class DownloadXmlTickets extends Component {
  static propTypes = {
    guids: PropTypes.array.isRequired,
    onDownload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const statuses = {};

    props.guids.forEach((guid) => {
      statuses[guid] = false;
    });

    this.state = {
      statuses,
    };
  }

  handleClick = (guid) => {
    if (this.state.statuses[guid]) {
      return () => {};
    }

    return this.props.onDownload(guid).then(() => {
      this.setState({
        statuses: {
          ...this.state.statuses,
          [guid]: true,
        },
      });
    });
  };

  renderGuid = (guid, ind) => {
    const wrapperStyles = [styles.text];

    const isDownload = this.state.statuses[guid];

    if (isDownload) {
      wrapperStyles.push(styles.download);
    }

    const statusHtml = () => {
      const status = isDownload ? LABELS.DOWNLOAD.YES : LABELS.DOWNLOAD.NO;

      return <span>{status}</span>;
    };

    return (
      <div
        key={ `xml_${guid}_${ind}` }
        className={ wrapperStyles.join(' ') }
        onClick={ () => this.handleClick(guid) }
      >
        { LABELS.TEXT } { ind + 1 } { statusHtml() }
      </div>
    );
  };

  render() {
    const { guids, onClose } = this.props;

    return (
      <Dialog onClick={ onClose } width={ SMALL }>
        <div className={ styles.wrapper }>
          <div className={ styles.title }>{ LABELS.TITLE }</div>
          <div className={ styles.guids }>
            { guids.map(this.renderGuid) }
          </div>
          <div className={ styles.button }>
            <Button
              label={ LABELS.BUTTON }
              onClick={ onClose }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DownloadXmlTickets };
