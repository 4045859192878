import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'sw-ui';

import COMPONENTS from '../../../../../bi/constants/components';

import DisabledAccount from '../../../../../components/DisabledAccount';

import styles from '../../styles/account.module.scss';

const LABEL = 'ЗАКРЫТЬ';

const DisabledAccountDialog = ({ onClose }) => (
  <Dialog onClick={ onClose } width='200px'>
    <div className={ styles.dialog_error__wrap }>
      <div className={ styles.dialog_error__text }>
        <DisabledAccount disabled />
      </div>
      <div className={ styles.dialog_error__button }>
        <Button
          label={ LABEL }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ onClose }
        />
      </div>
    </div>
  </Dialog>
);

DisabledAccountDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DisabledAccountDialog;
