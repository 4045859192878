import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MultiSelect } from 'sw-ui';

import {
  diffDays,
  formatDate,
  getMoment, isMoment,
  isValidMomentObject,
  momentObject,
} from '../../../../bi/utils/formatDate';

import { DATE } from '../../../../constants/time';
import { DATES } from '../../../../constants/dates';

import { FlatButton } from '../../../../components/button';
import NoResult from '../../../../components/NoResult';

import { SelectDate } from './SelectDate';

import { DownloadXmlTickets } from './dialogs/downloadXmlTickets';
import styles from '../styles/account.module.scss';

const OPTIONS = [
  { label: 'Любая дата', value: 0 },
  { label: 'Выбрать число', value: 1 },
  { label: 'Выбрать период', value: 2 },
];

const LABELS = {
  COMPANIES: {
    COMPANIES: 'Организации',
    COMPANIES_WITH_DEPARTMENTS: 'Организации и отделы',
    DEPARTMENTS: 'Отделы',
    FIELD: 'companies',
    VALUE: 'Организации',
  },
  COMPANIES_PLACEHOLDERS: {
    COMPANY: 'Название организации',
    COMPANY_OR_DEPARTMENT: 'Название организации или отдела',
    DEPARTMENT: 'Название отдела',
  },
  PROJECTS: {
    VALUE: 'Центры затрат',
    FIELD: 'projects',
  },
  NAME_OF_THE_COST_CENTER: 'Название центра затрат',
  TAGS: 'Метки',
  TRIPTAGS: {
    VALUE: 'Метки',
    FIELD: 'tripTags',
  },
  ANALYTICS: {
    FIELD: 'analytics',
  },
  RESET_FILTERS: 'Сбросить все фильтры',
  DOWNLOAD_ANALYTIC_REPORT: 'Скачать аналитический отчет',
  DOWNLOAD_TICKETS_XML: 'Скачать билеты XML',
  ANALYTICS_ERROR_PERIOD: 'Период должен быть не более 30 дней',
  ANALYTICS_ERROR_CHOOSE_PERIOD: 'Выберите период для скачивания отчета',
  SEND_XML_ERROR_CHOOSE_PERIOD: 'Выберите период отправки билетов в 1С Бухгалтерия',
  XML_TICKET_DOWNLOAD_ERROR_PERIOD: 'Период должен быть не более 7 дней',
  XML_TICKET_DOWNLOAD_CHOOSE_PERIOD: 'Выберите период для скачивания билетов',
  XML_TICKET_DOWNLOAD_NO_TICKETS: 'За данный период нет билетов',
  SEND_XML: 'Отправить XML',
  ERROR_MESSAGE_SEND_XML: 'Не удалось отправить',
};

const getObjectForSelect = item => (
  Array.isArray(item) ? { label: item[1], value: item[0] } : { label: item, value: item }
);

const preparedIf = (opt, first, second) => opt === OPTIONS[0].value || (opt === OPTIONS[1].value && !first && !second);

class TripFilters extends Component {
  static propTypes = {
    trips: PropTypes.arrayOf(PropTypes.object).isRequired,
    departments: PropTypes.array.isRequired,
    companyService: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    accountTripTags: PropTypes.array.isRequired,
    analytics: PropTypes.array.isRequired,
    filteredTrips: PropTypes.array.isRequired,
    accountId: PropTypes.number.isRequired,
    getXmlVisibilityFlag: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.setFilters();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trips !== prevProps.trips) {
      this.setFilters();
      this.filter();
    }
  }

  handleChange = ({ FIELD, VALUE }, values) => {
    this.setState({
      [FIELD]: {
        ...this.state[FIELD],
        selected: values,
      },
    }, this.filter);
  };

  filter = () => {
    const { trips, onChange, companyService, companies, analytics: analyticsList } = this.props;

    const {
      cities: { selected: cities },
      employees: { selected: employees },
      services: { selected: services },
      statuses: { selected: statuses },
      dates: { startDate, endDate },
      bookingDate: { startDate: bookingStartDate, endDate: bookingEndDate },
      companies: { selected: companiesState },
      projects: { selected: projects },
      tripTags: { selected: tripTags },
      analytics: { selected: analytics },
    } = this.state;

    const hasDepartments = companiesState.some(i => i && i.nested);

    const { requiredTripAnalytics, requiredServiceAnalytics } = analytics.reduce((acc, valueId) => {
      const parentAnalytics = analyticsList.find(({ Values = [] }) => Values.some(({ Id }) => Id === valueId));

      if (!parentAnalytics) {
        return acc;
      }

      if (parentAnalytics.ApplyToTrip) {
        return {
          ...acc,
          requiredTripAnalytics: [
            ...acc.requiredTripAnalytics,
            valueId,
          ],
        };
      }

      return {
        ...acc,
        requiredServiceAnalytics: [
          ...acc.requiredServiceAnalytics,
          valueId,
        ],
      };
    }, {
      requiredServiceAnalytics: [],
      requiredTripAnalytics: [],
    });

    const { companiesList = [], departmentsList = [] } = hasDepartments
      ? companiesState.reduce((r, { main, nested }) => ({
        companiesList: companies.length === 1 && nested.length === 0 ? r.companiesList : [...r.companiesList, main],
        departmentsList: nested.length ? [...r.departmentsList, ...nested] : r.departmentsList,
      }), { companiesList: [], departmentsList: [] })
      : { companiesList: companiesState };

    const stringifiedCompanies = companiesList.map(i => String(i));
    const stringifiedDepartments = departmentsList.map(i => String(i));

    const filteredTrips = trips.filter((trip) => {
      let datesPassed = startDate === null
        || startDate.isBetween(trip.CheckInDate, trip.CheckOutDate, 'day', '[]');

      if (endDate !== null && !datesPassed) {
        datesPassed = momentObject(trip.CheckInDate).isBetween(startDate, endDate, 'day', '[]') ||
          momentObject(trip.CheckOutDate).isBetween(startDate, endDate, 'day', '[]');
      }

      const minBookingEventDate = trip.Items.length
        ? moment.min(trip.Items.map(({ EventDate }) => momentObject(EventDate)))
        : null;

      let datesBookingPassed = bookingStartDate === null
        || ((isMoment(minBookingEventDate) && bookingStartDate.format(DATE) === formatDate(minBookingEventDate, DATE))
          || bookingStartDate.format(DATE) === formatDate(trip.ChangeDate, DATE));

      if (bookingEndDate !== null && !datesBookingPassed) {
        datesBookingPassed = (isMoment(minBookingEventDate)
            && minBookingEventDate.isBetween(bookingStartDate, bookingEndDate, 'day', '[]'))
          || momentObject(trip.ChangeDate).isBetween(bookingStartDate, bookingEndDate, 'day', '[]');
      }

      const { UserAnalytics: tripAnalytics = [], Items = [] } = trip;
      const hasAnalytics = tripAnalytics.some(id => requiredTripAnalytics.includes(id))
        || Items.some(({ UserAnalytics: serviceAnalytics = [] }) => serviceAnalytics.some(id => requiredServiceAnalytics.includes(id)));

      return datesPassed &&
        datesBookingPassed &&
        (!cities.length || trip.Cities.some(item => cities.includes(item))) &&
        (!employees.length || trip.EmployeesFull.some(item => employees.includes(item.Full))) &&
        (!stringifiedCompanies.length || trip.Companies.some(item => stringifiedCompanies.includes(String(item)) && (stringifiedDepartments.length ? true : trip.Departments.length === 0))) &&
        (!stringifiedDepartments.length || trip.Departments.some(item => stringifiedDepartments.includes(String(item))) || trip.Departments.length === 0) &&
        (!projects.length || trip.Items.some(item => projects.includes(item.ProjectId))) &&
        (!tripTags.length || trip.Items.some(item => item.Tags && item.Tags.some(({ Id }) => tripTags.includes(Id)))) &&
        (!services.length || trip.Services.some(item => services.includes(item))) &&
        (!statuses.length || statuses.includes(companyService.getAllTripStatuses().get(trip.Status))) &&
        (!analytics.length || hasAnalytics);
    });

    onChange(filteredTrips, {
      cities,
      employees,
      services,
      statuses,
      startDate: isValidMomentObject(startDate) ? formatDate(startDate, 'YYYYMMDD') : [],
      endDate: isValidMomentObject(endDate) ? formatDate(endDate, 'YYYYMMDD') : [],
      companies: companiesList,
      departments: hasDepartments ? departmentsList : [],
      projects,
      tripTags,
      analytics,
    });
  };

  setFilters = () => {
    const { trips, departments, projects, accountTripTags } = this.props;

    const cities = new Set();
    const employees = new Set();
    const departmentsSet = new Set();
    const companiesSet = new Set();
    const tripTags = new Set();
    const analyticsSet = new Set();
    const tripStatusesSet = new Set(this.props.companyService.getAllTripStatuses().values());

    trips.forEach(({ Cities, EmployeesFull, Items, Companies = [], Departments = [], UserAnalytics = [] }) => {
      Cities.forEach((item) => { if (item) cities.add(item); });
      EmployeesFull.forEach((item) => { if (item) employees.add(item.Full); });
      Items.forEach(({ Tags = [], UserAnalytics: itemsAnalytics = [] }) => {
        Tags.forEach(({ Id }) => { if (Id) tripTags.add(Id); });
        itemsAnalytics.forEach(analyticsSet.add, analyticsSet);
      });
      Companies.forEach(i => companiesSet.add(i));
      Departments.forEach(i => departmentsSet.add(i));
      UserAnalytics.forEach(analyticsSet.add, analyticsSet);
    });

    const companiesList = departments.reduce((r, { CompanyId, ShortCompanyName, CompanyName, Departments }) => {
      if (companiesSet.has(CompanyId)) {
        const reducedDepartments = Departments.reduce((dr, { Id, Name }) => {
          if (departmentsSet.has(Id)) {
            return [...dr, { value: Id, label: Name }];
          }

          return dr;
        }, []);

        const nested = reducedDepartments.length ? reducedDepartments : null;
        const newCompany = { value: CompanyId, label: ShortCompanyName || CompanyName };

        if (nested) {
          newCompany.nested = nested;
        }

        return [...r, newCompany];
      }

      return r;
    }, []);

    const tripTagsList = accountTripTags.filter(({ value }) => tripTags.has(value));
    const projectList = projects.map(({ Id, Name }) => ({ value: Id, label: Name }));

    const stateMapper = (list, selected = []) => ({ list, selected });

    if (!this.state) {
      this.state = {
        cities: stateMapper(Array.from(cities).sort().map(getObjectForSelect)),
        employees: stateMapper(Array.from(employees).sort().map(getObjectForSelect)),
        services: stateMapper(Array.from(this.props.companyService.getAllTripServices(), getObjectForSelect)),
        statuses: stateMapper(Array.from(tripStatusesSet, getObjectForSelect)),
        tripTags: stateMapper(tripTagsList),
        companies: stateMapper(companiesList),
        projects: stateMapper(projectList),
        analytics: stateMapper([...analyticsSet]),
        dates: {
          startDate: null,
          endDate: null,
        },
        bookingDate: {
          startDate: null,
          endDate: null,
        },
        singleDate: false,
        rangeDate: false,
        singleBookingDate: false,
        rangeBookingDate: false,
        options: OPTIONS[0].value,
        optionsBookingDate: OPTIONS[0].value,
        waitingAnalyticsReport: false,
        waitingSendXml: false,
        showErrorSendXml: false,
        waitingDownloadXmlTicket: false,
        analyticsError: '',
        downloadXmlTicket: {
          error: '',
          dialog: false,
          guids: [],
        },
      };
    } else {
      this.setState({
        ...this.state,
        cities: stateMapper(Array.from(cities).sort().map(getObjectForSelect), [...this.state.cities.selected]),
        employees: stateMapper(Array.from(employees).sort().map(getObjectForSelect), [...this.state.employees.selected]),
        services: stateMapper(Array.from(this.props.companyService.getAllTripServices(), getObjectForSelect), [...this.state.services.selected]),
        statuses: stateMapper(Array.from(tripStatusesSet, getObjectForSelect), [...this.state.statuses.selected]),
        tripTags: stateMapper(tripTagsList, [...this.state.tripTags.selected]),
        companies: stateMapper(companiesList, [...this.state.companies.selected]),
        projects: stateMapper(projectList, [...this.state.projects.selected]),
        analytics: stateMapper([...analyticsSet], [...this.state.analytics.selected]),
      });
    }
  };

  handleCitiesChanged = (values) => {
    this.setState({
      cities: {
        ...this.state.cities,
        selected: values,
      },
    }, this.filter);
  };

  handleEmployeesChanged = (values) => {
    this.setState({
      employees: {
        ...this.state.employees,
        selected: values,
      },
    }, this.filter);
  };

  handleServicesChanged = (values) => {
    this.setState({
      services: {
        ...this.state.services,
        selected: values,
      },
    }, this.filter);
  };

  handleStatusesChanged = (values) => {
    this.setState({
      statuses: {
        ...this.state.statuses,
        selected: values,
      },
    }, this.filter);
  };

  handleDatesChanged = (field, value) => {
    const { dates, options } = this.state;

    const currentValue = value !== '' ? value : null;

    if (field === DATES.STARTDATE) {
      dates.startDate = currentValue;
    } else if (field === DATES.ENDDATE) {
      dates.endDate = currentValue;
    }

    if (options === OPTIONS[1].value && value === '') {
      dates.startDate = null;
      dates.endDate = null;
    }

    this.setState({
      dates,
    }, this.filter);
  };

  handleBookingDatesChanged = (field, value) => {
    const { bookingDate, optionsBookingDate } = this.state;

    const currentValue = value !== '' ? value : null;

    if (field === DATES.STARTDATE) {
      bookingDate.startDate = currentValue;
    } else if (field === DATES.ENDDATE) {
      bookingDate.endDate = currentValue;
    }

    if (optionsBookingDate === OPTIONS[1].value && value === '') {
      bookingDate.startDate = null;
      bookingDate.endDate = null;
    }

    this.setState({
      bookingDate,
    }, this.filter);
  };

  handleSetDate = (startDate, endDate) => this.setState({ dates: { startDate, endDate } });

  handleSetBookingDate = (startDate, endDate) => this.setState({ bookingDate: { startDate, endDate } });

  handleDateTypeChange = (item) => {
    let { singleDate, rangeDate } = this.state;

    switch (item.value) {
      case 0:
        singleDate = false;
        rangeDate = false;
        this.handleSetDate(null, null);

        break;
      case 1:
        singleDate = true;
        rangeDate = false;
        this.handleSetDate(null, null);

        break;
      case 2:
        singleDate = false;
        rangeDate = true;
        this.handleSetDate(getMoment(), getMoment());

        break;
    }

    this.setState({
      singleDate,
      rangeDate,
      options: item.value,
    }, this.filter);
  };

  handleBookingDateTypeChange = ({ value }) => {
    let { singleBookingDate, rangeBookingDate } = this.state;

    switch (value) {
      case 0:
        singleBookingDate = false;
        rangeBookingDate = false;
        this.handleSetBookingDate(null, null);

        break;
      case 1:
        singleBookingDate = true;
        rangeBookingDate = false;
        this.handleSetBookingDate(null, null);

        break;
      case 2:
        singleBookingDate = false;
        rangeBookingDate = true;
        this.handleSetBookingDate(getMoment(), getMoment());

        break;
    }

    this.setState({
      singleBookingDate,
      rangeBookingDate,
      optionsBookingDate: value,
    }, this.filter);
  };

  handleResetFilters = () => {
    this.setState({
      cities: {
        ...this.state.cities,
        selected: [],
      },
      employees: {
        ...this.state.employees,
        selected: [],
      },
      services: {
        ...this.state.services,
        selected: [],
      },
      statuses: {
        ...this.state.statuses,
        selected: [],
      },
      dates: {
        startDate: null,
        endDate: null,
      },
      bookingDate: {
        startDate: null,
        endDate: null,
      },
      tripTags: {
        ...this.state.tripTags,
        selected: [],
      },
      companies: {
        ...this.state.companies,
        selected: [],
      },
      projects: {
        ...this.state.projects,
        selected: [],
      },
      analytics: {
        ...this.state.analytics,
        selected: [],
      },
      options: OPTIONS[0].value,
      singleDate: false,
      rangeDate: false,
      singleBookingDate: false,
      rangeBookingDate: false,
    }, this.filter);
  };

  handlePreparedData = (startDate, endDate, options, textError, isSendXml) => {
    const { filteredTrips, accountId, companyId } = this.props;
    const monthDays = 30;

    const noDates = preparedIf(options, startDate, endDate);

    if (diffDays(momentObject(startDate), momentObject(endDate)) > monthDays) {
      return this.setState({ analyticsError: LABELS.ANALYTICS_ERROR_PERIOD });
    }

    if (options === OPTIONS[0].value) {
      return this.setState({ analyticsError: textError });
    }

    if (isSendXml && noDates) {
      return this.setState({ analyticsError: textError });
    }

    this.setState({ analyticsError: '' });

    const start = startDate
      ? formatDate(startDate, DATE)
      : null;
    const end = endDate
      ? formatDate(endDate, DATE)
      : null;

    if (!startDate) {
      this.setState({
        dates: {
          ...this.state.dates,
          startDate: getMoment(),
        },
      });
    }

    if (!endDate) {
      this.setState({
        dates: {
          ...this.state.dates,
          endDate: startDate,
        },
      });
    }

    const tripIds = [];
    filteredTrips.forEach(({ Items }) => Items.forEach(({ Id }) => tripIds.push(Id)));

    return {
      AccountId: accountId,
      TripItemsId: tripIds,
      StartDate: start,
      EndDate: end,
      CompanyId: companyId,
    };
  }

  handleShowErrorSendXmlDialog = () => this.setState({ waitingSendXml: false, showErrorSendXml: !this.state.showErrorSendXml })

  handleDownloadAnalyticReport = (params) => {
    const { companyService } = this.props;

    return companyService.downloadAnalytics(params).then(() => this.setState({ waitingAnalyticsReport: false }));
  };

  handleSendXmlIn1C = (params) => {
    const { companyService } = this.props;

    return companyService.sendXml(params)
      .then(() => this.setState({ waitingSendXml: false }))
      .catch(() => this.handleShowErrorSendXmlDialog());
  };

  handleDownloadAnalReport = () => {
    const {
      dates: { startDate, endDate },
      options,
    } = this.state;

    const { AccountId, TripItemsId, StartDate, EndDate }
      = this.handlePreparedData(startDate, endDate, options, LABELS.ANALYTICS_ERROR_CHOOSE_PERIOD);

    this.setState({ waitingAnalyticsReport: true });
    this.handleDownloadAnalyticReport({ AccountId, TripItemsId, StartDate, EndDate });
  };

  handleSendXml = () => {
    const {
      bookingDate: { startDate, endDate },
      optionsBookingDate,
    } = this.state;

    const { CompanyId, StartDate, EndDate } =
      this.handlePreparedData(startDate, endDate, optionsBookingDate, LABELS.SEND_XML_ERROR_CHOOSE_PERIOD, true);

    this.setState({ waitingSendXml: true });
    this.handleSendXmlIn1C({ CompanyId, StartDate, EndDate });
  };

  handleChangeXmlTicketError = (value = '') =>
    this.setState({
      downloadXmlTicket: {
        ...this.state.downloadXmlTicket,
        error: value,
      },
    });

  handleChangeXmlTicket = (dialog, guids) => this.setState({
    downloadXmlTicket: {
      ...this.state.downloadXmlTicket,
      dialog,
      guids,
    },
  });

  handleDownloadXmlButton = () => {
    const {
      dates: { startDate, endDate },
      bookingDate: { startDate: bookingStartDate, endDate: bookingEndDate },
      options,
      optionsBookingDate,
    } = this.state;
    const { companyService, companyId } = this.props;

    const noDates = preparedIf(options, startDate, endDate);
    const noBookingDates = preparedIf(optionsBookingDate, bookingStartDate, bookingEndDate);

    if (noDates && noBookingDates) {
      return this.handleChangeXmlTicketError(LABELS.XML_TICKET_DOWNLOAD_CHOOSE_PERIOD);
    }

    this.handleChangeXmlTicketError();

    const formattedDate = (first, second) => (first ? formatDate(first, DATE) : formatDate(second, DATE));

    let start = null;
    let end = null;

    if (!noDates) {
      start = formattedDate(startDate, endDate);
      end = formattedDate(endDate, startDate);

      if (options === OPTIONS[1].value && end !== start) {
        end = start;
      }

      if (!startDate) {
        this.setState({
          dates: {
            ...this.state.dates,
            startDate: getMoment(),
          },
        });
      }

      if (!endDate) {
        this.setState({
          dates: {
            ...this.state.dates,
            endDate: startDate,
          },
        });
      }

      if (options === OPTIONS[1].value) {
        this.setState({
          dates: {
            ...this.state.dates,
            endDate: startDate,
          },
        });
      }
    }

    let bookingStart = null;
    let bookingEnd = null;

    if (!noBookingDates) {
      bookingStart = formattedDate(bookingStartDate, bookingEndDate);
      bookingEnd = formattedDate(bookingEndDate, bookingStartDate);

      if (optionsBookingDate === OPTIONS[1].value && bookingEnd !== bookingStart) {
        bookingEnd = bookingStart;
      }

      if (!bookingStart) {
        this.setState({
          bookingDate: {
            ...this.state.bookingDate,
            startDate: getMoment(),
          },
        });
      }

      if (!bookingEnd) {
        this.setState({
          bookingDate: {
            ...this.state.bookingDate,
            endDate: bookingStart,
          },
        });
      }

      if (optionsBookingDate === OPTIONS[1].value) {
        this.setState({
          bookingDate: {
            ...this.state.bookingDate,
            endDate: bookingStart,
          },
        });
      }
    }

    const params = {
      CompanyId: companyId,
      StartEventDate: bookingStart,
      EndEventDate: bookingEnd,
      CheckinDate: start,
      CheckoutDate: end,
    };

    this.setState({ waitingDownloadXmlTicket: true });

    return companyService.getXmlTickets(params)
      .then((res) => {
        if (res.status === 404) {
          return this.handleChangeXmlTicketError(LABELS.XML_TICKET_DOWNLOAD_NO_TICKETS);
        }

        if (res.length > 0) {
          return this.handleChangeXmlTicket(true, res);
        }

        return this.handleChangeXmlTicket(false, []);
      })
      .finally(() => this.setState({ waitingDownloadXmlTicket: false }));
  };

  handleDownloadXmlTicket = guid => this.props.companyService.downloadXmlTicket(guid);

  renderAnalyticsError = () => {
    const { analyticsError } = this.state;

    return analyticsError.length > 0 && (
      <NoResult
        onClose={ () => this.setState({ analyticsError: '' }) }
        message={ analyticsError }
      />
    );
  }

  renderDownloadXmlButton = () => this.props.getXmlVisibilityFlag() && (
    <div className={ styles['filter-button'] }>
      <FlatButton
        loading={ this.state.waitingDownloadXmlTicket }
        onClick={ this.handleDownloadXmlButton }
      >
        { LABELS.DOWNLOAD_TICKETS_XML }
      </FlatButton>
    </div>
  );

  renderSendXmlButton = () => {
    const { waitingSendXml } = this.state;
    const { companyId, companies } = this.props;

    const companyFinder = companies ? companies.find(({ CompanyId }) => CompanyId === companyId) : null;
    const showButton = companyFinder ? !!companyFinder.Integration1CInfo : false;

    return showButton ? (
      <div className={ styles['filter-button'] }>
        <FlatButton loading={ waitingSendXml } onClick={ this.handleSendXml }>{ LABELS.SEND_XML }</FlatButton>
      </div>
    ) : null;
  };

  renderDownloadXmlButtonError = () => {
    const { downloadXmlTicket: { error } } = this.state;

    return error.length > 0 && (
      <NoResult
        message={ error }
        onClose={ this.handleChangeXmlTicketError }
      />
    );
  };

  renderCompaniesFilter = () => {
    const { companies: { list, selected } } = this.state;

    const hasDepartments = list.some(({ nested }) => nested && nested.length);

    if (list.length === 1 && hasDepartments) {
      const companyId = list[0].value;
      const values = selected[0] ? selected[0].nested : [];

      return (
        <div className={ styles.filter }>
          <MultiSelect
            label={ LABELS.COMPANIES.DEPARTMENTS }
            items={ list.reduce((r, { nested }) => ([...r, ...nested]), []) }
            values={ values }
            search
            searchPlaceholder={ LABELS.COMPANIES.DEPARTMENTS }
            onChange={ nested => this.handleChange(LABELS.COMPANIES, [{ main: companyId, nested }]) }
          />
        </div>
      );
    }

    if (list.length > 1) {
      const placeholder = hasDepartments ? LABELS.COMPANIES_PLACEHOLDERS.COMPANY_OR_DEPARTMENT : LABELS.COMPANIES_PLACEHOLDERS.COMPANY;
      const label = hasDepartments ? LABELS.COMPANIES.COMPANIES_WITH_DEPARTMENTS : LABELS.COMPANIES.COMPANIES;

      return (
        <div className={ styles.filter }>
          <MultiSelect
            hasNested
            label={ label }
            items={ list }
            values={ selected }
            search
            searchPlaceholder={ placeholder }
            onChange={ values => this.handleChange(LABELS.COMPANIES, values) }
          />
        </div>
      );
    }

    return null;
  };

  renderProjectsFilter = () => {
    const { projects: { list, selected } } = this.state;

    return list.length > 0 && (
      <div className={ styles.filter }>
        <MultiSelect
          label={ LABELS.PROJECTS.VALUE }
          items={ list }
          values={ selected }
          search
          searchPlaceholder={ LABELS.NAME_OF_THE_COST_CENTER }
          onChange={ values => this.handleChange(LABELS.PROJECTS, values) }
        />
      </div>
    );
  }

  renderTripTagsFilter = () => {
    const { tripTags: { list, selected } } = this.state;

    return list.length > 0 && (
      <div className={ styles.filter }>
        <MultiSelect
          label={ LABELS.TRIPTAGS.VALUE }
          items={ list }
          values={ selected }
          search
          searchPlaceholder={ LABELS.TAGS }
          onChange={ values => this.handleChange(LABELS.TRIPTAGS, values) }
        />
      </div>
    );
  };

  renderCustomAnalytics = () => {
    const { analytics = [] } = this.props;
    const { analytics: { selected = [], list: availableValueIds = [] } } = this.state;

    return analytics.reduce((acc, { Id, Name, Values }) => {
      const values = selected.filter(selectedValueId => Values.some(({ Id: valueId }) => valueId === selectedValueId));
      const list = Values.reduce((valuesAcc, { Value, Id: valueId }) => {
        if (!availableValueIds.includes(valueId)) {
          return valuesAcc;
        }

        return [
          ...valuesAcc,
          {
            label: Value,
            value: valueId,
          },
        ];
      }, []);

      if (!list.length) {
        return acc;
      }

      return [
        ...acc,
        <div key={ Id } className={ styles.filter }>
          <MultiSelect
            label={ Name }
            items={ list }
            values={ values }
            search
            onChange={ (v) => {
              this.handleChange(LABELS.ANALYTICS, [
                ...selected.filter(i => !list.some(({ value }) => value === i)),
                ...v,
              ]);
            } }
          />
        </div>,
      ];
    }, []);
  };

  renderDownloadXmlTicketsDialog = () => {
    const { downloadXmlTicket: { dialog, guids } } = this.state;

    if (!dialog) {
      return null;
    }

    return (
      <DownloadXmlTickets
        guids={ guids }
        onDownload={ this.handleDownloadXmlTicket }
        onClose={ () => this.handleChangeXmlTicket(false, []) }
      />
    );
  };

  renderErrorSendXmlDialog = () => (
    <NoResult
      message={ LABELS.ERROR_MESSAGE_SEND_XML }
      onClose={ this.handleShowErrorSendXmlDialog }
    />
  );

  render() {
    const {
      cities,
      employees,
      services,
      statuses,
      singleDate,
      rangeDate,
      singleBookingDate,
      rangeBookingDate,
      options,
      optionsBookingDate,
      waitingAnalyticsReport,
      dates,
      bookingDate,
      showErrorSendXml,
    } = this.state;

    const errorXmlDialogHtml = showErrorSendXml && this.renderErrorSendXmlDialog();

    return (
      <div className={ styles.filters }>
        <div className={ styles.filter }>
          <MultiSelect
            label='Города'
            items={ cities.list }
            values={ cities.selected }
            search
            searchPlaceholder='Название города'
            onChange={ this.handleCitiesChanged }
          />
        </div>
        <div className={ styles.filter }>
          <MultiSelect
            label='Сотрудники'
            items={ employees.list }
            values={ employees.selected }
            search
            searchPlaceholder='Фамилия сотрудника'
            onChange={ this.handleEmployeesChanged }
          />
        </div>
        <div className={ styles.filter }>
          <MultiSelect
            label='Услуги'
            items={ services.list }
            values={ services.selected }
            onChange={ this.handleServicesChanged }
          />
        </div>
        <div className={ styles.filter }>
          <MultiSelect
            label='Статус'
            items={ statuses.list }
            values={ statuses.selected }
            onChange={ this.handleStatusesChanged }
          />
        </div>
        <SelectDate
          label={ 'Дата поездки' }
          items={ OPTIONS }
          value={ options }
          singleDate={ singleDate }
          rangeDate={ rangeDate }
          dates={ dates }
          onChange={ this.handleDateTypeChange }
          onChangedDates={ this.handleDatesChanged }
        />
        <SelectDate
          label={ 'Дата бронирования' }
          items={ OPTIONS }
          value={ optionsBookingDate }
          singleDate={ singleBookingDate }
          rangeDate={ rangeBookingDate }
          dates={ bookingDate }
          onChange={ this.handleBookingDateTypeChange }
          onChangedDates={ this.handleBookingDatesChanged }
        />
        { this.renderCompaniesFilter() }
        { this.renderProjectsFilter() }
        { this.renderTripTagsFilter() }
        { this.renderCustomAnalytics() }
        <div className={ `${styles['filter-row']} ${styles['items-right']}` }>
          { this.renderSendXmlButton() }
          { this.renderDownloadXmlButton() }
          <div className={ styles['filter-button'] }>
            <FlatButton loading={ waitingAnalyticsReport } onClick={ this.handleDownloadAnalReport }>{ LABELS.DOWNLOAD_ANALYTIC_REPORT }</FlatButton>
          </div>
          <div className={ styles['filter-button'] }>
            <FlatButton onClick={ this.handleResetFilters }>{ LABELS.RESET_FILTERS }</FlatButton>
          </div>
        </div>
        { this.renderAnalyticsError() }
        { this.renderDownloadXmlButtonError() }
        { this.renderDownloadXmlTicketsDialog() }
        { errorXmlDialogHtml }
      </div>
    );
  }
}

export default TripFilters;
