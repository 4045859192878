import React, { Fragment, useContext } from 'react';
import { Tabs, TabsItem } from 'sw-ui';

import { THEMEACTION } from '../../../bi/constants/rights';
import VacationAccountContext from '../context';
import Account from './account';
import { Statistic } from '../../account/components/statistic';
import AllEmployees from './allEmployees/allEmployees';

const LABELS = {
  ACCOUNT: 'Профиль аккаунта',
  PRODUCT: 'Продукт',
  ALL_EMPLOYEES: 'Все сотрудники',
};

const IDS = {
  ACCOUNT: 'account',
  PRODUCT: 'product',
  ALL_EMPLOYEES: 'allEmployees',
};

const TopTabs = () => {
  const {
    appParams,
    userService,
    accountService,
    companyService,
    notificationsService,
    featureFlagsService,
    tripService,
    employeeService,
    moveTo,
    staticsService,
  } = useContext(VacationAccountContext);
  const { mainTabId, companyId, accountId } = appParams;

  const handleTabPress = (id) => {
    moveTo({ mainTabId: id });
  };

  const toAnotherCompany = (id) => {
    moveTo({ companyId: id });
  };

  return (
    <Fragment>
      <Tabs theme={ THEMEACTION.DEFAULT } activeTabId={ mainTabId } onClick={ handleTabPress }>
        <TabsItem id={ IDS.ACCOUNT } title={ LABELS.ACCOUNT }>
          <Account
            accountId={ accountId }
            companyId={ companyId }
            userService={ userService }
            accountService={ accountService }
            companyService={ companyService }
            notificationsService={ notificationsService }
            toAnotherCompany={ toAnotherCompany }
            featureFlagsService={ featureFlagsService }
            tripService={ tripService }
          />
        </TabsItem>
        <TabsItem id={ IDS.PRODUCT } title={ LABELS.PRODUCT }>
          <Statistic
            accountId={ accountId }
            staticsService={ staticsService }
          />
        </TabsItem>
        <TabsItem id={ IDS.ALL_EMPLOYEES } title={ LABELS.ALL_EMPLOYEES }>
          <AllEmployees employeeService={ employeeService } params={ appParams } />
        </TabsItem>
      </Tabs>
    </Fragment>
  );
};

export default TopTabs;
